import React from 'react'

import styled from 'react-emotion'

const Clickable = styled('button')`
  border-radius: 1000px;
  font-size: 15px;
  padding: 6px 20px;
  border: none;
  background-color: ${props => props.light ? '#FCFCFC' : '#70CBD3'};
  color: ${props => props.light ? '#70CBD3' : '#FCFCFC'};
  transition: all 0.15s ease-in-out;
  letter-spacing: 1px;
  font-weight: ${props => props.light ? '600' : '400'};
  appearance: none;

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => props.light ? '#FFF' : '#3db0ba'};
    transition: all 0.15s ease-in-out;
  }
`

const Text = styled('button')`
  appearance: none;
  border-radius: 1000px;
  font-size: 15px;
  border: none;
  transition: all 0.15s ease-in-out;
  letter-spacing: 1px;
  font-weight: 400;
  
  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    color: #3db0ba;
    transition: all 0.15s ease-in-out;
  }
`

export const Button = (props) => {

  if (props.text) {
    return (
      <Text onClick={props.onClick}>
        {props.children}
      </Text>
    )
  }

  return (
    <Clickable onClick={props.onClick} light={props.light}>
      {props.children || 'Get Started'}
    </Clickable>
  )
}