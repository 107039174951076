import React from 'react'
import Link from 'gatsby-link'

import { Button } from './button'
import styled, { css } from 'react-emotion'
import logo from './images/logo.svg'

let scrollToComponent

const HeaderStyles = styled('div')`
  z-index: 2;

  h1 {

    a {
      color: #70CBD3;
    }

    span {
      font-weight: 400;
      margin-left: 10px;
      font-size: 16px;
      letter-spacing: 0.3px;
    }
  }

  nav ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    li {
      display: inline-block;
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }

  @media (max-width: 500px) {
    grid-gap: 20px;

    h1 {
      font-size: 1.15rem;

      span {
        font-size: 0.85rem;
      }
    }

    button {
      padding: 0.5rem 0.75rem;
      font-size: 0.75rem;
    }
  }
`

export class Header extends React.Component {

  componentDidMount = () => (
    scrollToComponent = require('react-scroll-to-component')
  )

  render() {

    const { props } = this

    return (
      <HeaderStyles
        style={{
          marginBottom: '1.45rem',
          position: 'relative'
        }}
      >
        <div
          className={css`
            margin: 0 auto;
            max-width: 1440px;
            padding: 1.45rem 1.0875rem;
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            grid-gap: 40px;

            @media (max-width: 500px) {
              grid-gap: 20px;
            }
          `}
        >
          <div>
              <Link
                to="/"
                style={{
                  textDecoration: 'none',
                }}
              >
                <img className={css`@media (max-width: 550px) {max-width: 200px;} width: auto; max-height: 100px; margin-bottom: 0;`} src={logo} alt=""/>
              </Link>
          </div>
          <div
            className={css`
              text-align: right;
            `}
          >
            <nav>
              <ul>
                <li className={css`@media (max-width: 768px) { display: none !important; }`}>
                  <Button
                    text
                    onClick={() => scrollToComponent(props.socialProof, { align: 'top'})}
                  >
                    Who I've Helped
                  </Button>
                </li>
                <li>
                  <Button onClick={() => scrollToComponent(props.getStarted, { align: 'top'})} >
                    Get Started
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </HeaderStyles>
    )
  }
}
