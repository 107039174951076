import React from "react"
import { Button } from './button'
import moment from 'moment'

// Below is where the checkout component is defined.
// It has several functions and some default state variables.
const Checkout = class extends React.Component {
  state = {
    disabled: false,
    buttonText: "Register Now",
    paymentMessage: "",
  }

  resetButton() {
    this.setState({ disabled: false, buttonText: "Register Now" })
  }

  componentDidMount() {
    this.stripeHandler = window.StripeCheckout.configure({
      key: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
      closed: () => {
        this.resetButton()
      },
    })
  }

  openStripeCheckout(event) {
    event.preventDefault()
    this.setState({ disabled: true, buttonText: "Waiting..." })
    this.stripeHandler.open({
      name: this.props.title,
      amount: Number(this.props.amount.replace('.','')),
      description: `${this.props.title}: ${moment(this.props.date, 'YYYY-MM-DD kk:mm:ss +0000').format('MMMM Do,' +
        ' YYYY')}`,
      token: token => {
        fetch(process.env.GATSBY_AWS_LAMBDA_URL, {
          method: "POST",
          mode: "no-cors",
          body: JSON.stringify({
            token,
            amount: Number(this.props.amount.replace('.','')),
            description: `${this.props.title}: ${moment(this.props.date, 'YYYY-MM-DD kk:mm:ss +0000').format('MMMM' +
              ' Do, YYYY')}`,
          }),
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        })
          .then(res => {
            console.log(res);
            console.log("Transaction processed successfully")
            this.resetButton()
            this.setState({ paymentMessage: "You've been registered successfully!" })
            return res
          })
          .catch(error => {
            console.error("Error:", error)
            this.setState({ paymentMessage: "Payment Failed" })
          })
      },
    })
  }

  render() {
    return (
      <div>
        <Button
          onClick={event => this.openStripeCheckout(event)}
          disabled={this.state.disabled}
          light
        >
          {this.state.buttonText}
        </Button>
        <p style={{ marginTop: '1.45rem' }}>
          {this.state.paymentMessage}
        </p>
      </div>
    )
  }
}

export { Checkout }