import React from 'react'

export const Triangle = (props) =>
  <svg width="2000px" height="93px" viewBox="0 0 2000 93" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <rect id="path-1" x="0" y="0" width="2000" height="93" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(0.000000, -1490.000000)">
        <g id="Group-5" transform="translate(0.000000, 1490.000000)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"/>
          </mask>
          <g id="Rectangle-6" />
          <ellipse id="Oval-4" fill={props.color ? props.color : '#FCFCFC'} mask="url(#mask-2)" cx="998.611111" cy="-295.833333" rx="1572.22222" ry="388.888889" />
        </g>
      </g>
    </g>
  </svg>
