import React from 'react'

import { Button } from './button'
import { Triangle } from './arrow'

import styled, { css } from 'react-emotion'

const ContactStyles = styled('div')`
  z-index: 1;
  position: relative;

  svg {
    display: block;
    width: 100%;
    height: auto;
    transform: rotate(-180deg);
  }
`

const Constrain = styled('div')`
  max-width: 940px;
  margin: auto;
`

const Padding = styled('div')`
  padding: 5rem 2.5rem 2.5rem;

  @media (max-width: 425px) {
    padding-bottom: 3.5rem;
  }
`

const ContactForm = styled('div')`
  display: grid;
  grid-gap: 30px;

  .required:after {
    content: '*';
    display: inline-block;
    margin-left: 2px;
  }

  @media (min-width: 769px) {
    grid-template-columns: repeat(2, 1fr);

    .full {
      grid-column: span 2;
    }
  }

  .inputGroup {
    text-align: left;

    &.error {

      label {
        color: #e12121;
      }

      input,
      textarea {
        border-color: #e12121;
      }
    }
  }

  label {
    font-family: 'Signika', sans-serif;
    font-weight: 600;
    text-transform: lowercase;
    font-variant: small-caps;
    padding-left: 0.75rem;
    letter-spacing: 0.5px;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    background-color: #FCFCFC;
    border-radius: 4px 4px 0 0;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(19,19,19,0.5);
    padding: 1rem 0.75rem;
    margin-bottom: 0.5rem;

    &:focus {
      outline: none;
      border-color: #73C4C6;
    }
  }
`

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      phone: '',
      message: '',
      botField: '',
      formStatus: '',
      validEmail: true,
      validLastName: true,
      validFirstName: true,
      validCompany: true,
      validPhone: true,
      validMessage: true
    }
  }

  validateForm = () => {

    const validEmail = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    const validLastName = this.state.firstName.length >= 2
    const validFirstName = this.state.lastName.length >= 2
    const validCompany = this.state.company.length >= 2
    const validPhone = this.state.phone.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i)
    const validMessage = this.state.message.length >= 2

    if (validEmail && validLastName && validFirstName && validPhone && validMessage && validCompany) {
      return true
    } else {
      this.setState({
        validEmail: validEmail,
        validLastName: validLastName,
        validFirstName: validFirstName,
        validCompany: validCompany,
        validPhone: validPhone,
        validMessage: validMessage
      })

      return false
    }

  }

  handleSubmit = e => {
    if (this.validateForm()) {
      if (this.state.botField !== '') {
        this.setState({ formStatus: 'success' })
        e.preventDefault()
      } else {
        fetch('/', {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "contact",
            "bot-field": this.state.botField,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            company: this.state.company,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
          }),
        })
          .then(() => this.setState({ formStatus: 'success' }))
          .catch(error => console.log(error))

        e.preventDefault()
      }
    }

    e.preventDefault()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    return (
      <ContactStyles>
          {/*<Triangle/>*/}
          <Constrain>
            <Padding>
              <div className={css`
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 60px;
                text-align: center;

                @media (max-width: 768px) {
                  grid-gap: 40px;
                }
              `}>
              <h2>Get Started</h2>
              <div>
                <form
                  name="contact"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <div style={{ display: 'none' }}>
                    <input name="bot-field" onChange={this.handleChange} />
                  </div>
                  <ContactForm>
                    <div className={`inputGroup ${!this.state.validFirstName ? 'error' : ''}`}>
                      <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor="" className="required">
                        First Name
                      </label>
                    </div>
                    <div className={`inputGroup ${!this.state.validLastName ? 'error' : ''}`}>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor="" className="required">
                        Last Name
                      </label>
                    </div>
                    <div className={`inputGroup full ${!this.state.validEmail ? 'error' : ''}`}>
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor="" className="required">
                        Email
                      </label>
                    </div>
                    <div className={`inputGroup ${!this.state.validCompany ? 'error' : ''}`}>
                      <input
                        type="text"
                        name="company"
                        placeholder="Company"
                        value={this.state.company}
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor="" className="required">
                        Company
                      </label>
                    </div>
                    <div className={`inputGroup ${!this.state.validPhone ? 'error' : ''}`}>
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor="" className="required">
                        Phone
                      </label>
                    </div>
                    <div className={`inputGroup full ${!this.state.validMessage ? 'error' : ''}`}>
                      <textarea
                        name="message"
                        placeholder="Message"
                        value={this.state.message}
                        cols="30"
                        rows="10"
                        onChange={this.handleChange}
                        required
                      />
                      <label htmlFor="" className="required">
                        Message
                      </label>
                    </div>
                    <div className="full" style={{ textAlign: 'right' }}>
                      <Button onClick={this.handleSubmit}>Submit</Button>
                      {this.state.formStatus === 'success' && (
                        <p
                          style={{ textAlign: 'center', margin: '1.5rem 0 0' }}
                        >
                          Your information was submitted successfully, I'll be
                          in touch soon.
                        </p>
                      )}
                    </div>
                  </ContactForm>
                </form>
              </div>
              </div>
            </Padding>
          </Constrain>
          <Triangle color="#70CBD3" />
      </ContactStyles>
    )
  }
}
