import React from "react"
import moment from "moment"

import styled, { css } from "react-emotion"

import facebook from './images/facebook.svg'
import instagram from './images/instagram.svg'

const FooterStyles = styled('div')`
  z-index: 1;
  position: relative;
  background-color: #70CBD3;
  color: #FCFCFC;
  text-align: right;
  align-items: center;
  overflow: hidden;

  p.builtBy {
    margin-top: 10px;
    line-height: 1;
    margin-bottom: 0;
    display: block;
    opacity: 0.9;

    a {
      color: #fcfcfc;
      transition: all 0.15s ease-in-out;
      text-decoration: none;

      &:hover {
        color: #3db0ba;
        transition: all 0.15s ease-in-out;
      }
    }
  }


  nav {
    display: inline-block;

    ul {
      list-style: none;
      margin-bottom: 0;

      li {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;

        &:not(:last-of-type) {
          margin-right: 20px;
        }

        a {
          color: #fcfcfc;
          font-size: 1.25rem;
          transition: all 0.15s ease-in-out;

          &:hover {
            color: #3db0ba;
            transition: all 0.15s ease-in-out;
          }
        }
      }
    }
  }

  @media (max-width: 425px) {
    text-align: center;
  }
`

const Constrain = styled('div')`
  max-width: 1440px;
  margin: auto;
`

export const Footer = (props) =>
  <FooterStyles>
    <Constrain>
      <div className={css`
        padding: 1.5rem 2.5rem 1.5rem;
        position: relative;
        z-index: 1;

        @media (max-width: 768px) {
          padding-top: 2.5rem;
          padding-bottom: 2.5rem;
        }
      `}>
        <h4 className={css`display: inline-block; margin-bottom: 0;`}>©{moment().format('YYYY')} Leslie Haas</h4>
        <nav>
          <ul>
            <li>
              <a href={`https://instagram.com/${props.instagram}`} target="_blank" rel="noopener">
                <img src={instagram} alt="instagram"/>
              </a>
            </li>
            <li>
              <a href={`https://facebook.com/${props.facebook}`} target="_blank" rel="noopener">
                <img src={facebook} alt="facebook"/>
              </a>
            </li>
          </ul>
        </nav>

        <p className="builtBy">
          <small>
            <a href="//iwantmybusinesstogrow.com" target="_blank" rel="noopener">Made by The Growth Company</a>
          </small>
        </p>
      </div>
    </Constrain>
  </FooterStyles>

