import React from 'react'
import { Button } from './button'
import styled, { css } from 'react-emotion'

let scrollToComponent

const HeroStyles = styled('div')`
  z-index: 1;
  position: relative;
  background-color: #fff;
  overflow: hidden;

  h1 {
    font-size: 52px;
    line-height: 1.25;
    color: #70CBD3;
  }

  h3 {
    font-family: 'Open Sans', serif;
    font-size: 20px;
    line-height: 1.75;
    font-weight: 400;
  }
`


class Hero extends React.Component {

  componentDidMount = () => (
    scrollToComponent = require('react-scroll-to-component')
  )

  render() {

    const { props } = this

    return (
      <HeroStyles>
        <div
          className={css`
              margin: 0 auto;
              max-width: 1200px;
              padding: 4.45rem 2.5rem;
              display: grid;
              align-items: center;
              grid-template-columns: 1.25fr 2fr;
              grid-gap: 80px;
              position: relative;
              z-index: 1;

              @media (max-width: 768px) {
                grid-template-columns: 1fr;
                grid-gap: 40px;
                margin-bottom: 120px;
                padding-top: 2.5rem;
              }
            `}
        >
          <div>
            <div className={css`max-width: 400px; margin: auto;`}>
              <div
                className={css`
                    background-image: url("${props.image}");
                    padding-top: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius: 100%;
                  `}
              />
            </div>
          </div>
          <div>
            <h1>
              {props.heading}
            </h1>
            <div dangerouslySetInnerHTML={{ __html: props.content }} />
            <Button onClick={() => scrollToComponent(props.scroll, { align: 'top' })}>
              Get Started
            </Button>
          </div>
        </div>
      </HeroStyles>
    )
  }
}

export { Hero }
