import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../_layouts/index.css'

import { Header } from './header'
import { Footer } from './footer'

let scrollToComponent

class Layout extends Component {

  componentDidMount = () => (
    scrollToComponent = require('react-scroll-to-component')
  )

  render () {
    return(
      <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        file(relativePath: { eq: "home.md"}) {
          childMarkdownRemark {
            frontmatter {
              title
              image
              seoTitle
              seoImage
              seoDescription
              instagramHandle
              facebookHandle
            }
            excerpt
          }
        }
      }
    `}
        render={data => (
          <>
            <Helmet
              title={`${data.file.childMarkdownRemark.frontmatter.seoTitle ? data.file.childMarkdownRemark.frontmatter.seoTitle : data.file.childMarkdownRemark.frontmatter.title } | ${data.site.siteMetadata.title}`}
              meta={[
                { name: 'description', content: data.file.childMarkdownRemark.frontmatter.seoDescription || data.file.childMarkdownRemark.excerpt },
                { name: 'keywords', content: 'social media, greenville sc' },
                { name: 'msapplication-TileColor', content: '#ffffff'},
                { name: 'theme-color', content: '#ffffff'}
              ]}
              link={[
                { href: "https://fonts.googleapis.com/css?family=Signika:300,600,700|Open+Sans:300,", rel: "stylesheet"},
                { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicon/apple-touch-icon.png' },
                { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon/favicon-32x32.png' },
                { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon/favicon-16x16.png' },
                { rel: 'manifest', href: '/favicon/site.webmanifest' },
                { rel: 'mask-icon', href: '/favicon/safari-pinned=tab.svg', color: '#73C4C6' }
              ]}
            >
              <html lang="en" />
            </Helmet>

            <div
              style={{
                margin: '0 auto',
                paddingTop: 0,
              }}
            >
              {this.props.children}
            </div>

            <Footer
              facebook={data.file.childMarkdownRemark.frontmatter.facebookHandle}
              instagram={data.file.childMarkdownRemark.frontmatter.instagramHandle}
            />
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
