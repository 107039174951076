import React, { Component } from "react"
import { StaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'

import { Hero } from '../components/hero'

import { Header } from '../components/header'

import { Triangle } from '../components/arrow'

import { Contact } from '../components/contact'
import { Checkout } from '../components/checkout'

import styled, { css } from 'react-emotion'

import Layout from '../components/layout'

const SocialProof = styled('div')`
  background-color: #fcfcfc;

  @media (max-width: 425px) {
    text-align: center;
  }

  h2 {
    margin-bottom: 0;

    @media (min-width: 769px) {
      margin-top: -60px;
    }
  }

  svg {
    width: 100%;
    height: auto;
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, 1fr) ;
    grid-gap: 60px;
    align-items: center;
    justify-content: center;

    a {
      max-width: 33.33%;
      min-width: 33.33%;
      box-sizing: border-box;
      margin-bottom: 60px;

      &:not(:last-of-type) {
        padding-left: 30px;
        padding-right: 30px;

        @media (max-width: 425px) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      @media (min-width: 426px) and (max-width: 768px) {
        max-width: 50%;
        min-width: 50%;
      }

      @media (max-width: 425px) {
        min-width: 50%;
        max-width: 50%;
        text-align: center;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    a:hover img {
      opacity: 0.85;
      transition: ease-in-out 0.25s all;
    }

    img {
      max-width: 300px;
      width: 100%;
      height: auto;
      margin: 0;
      filter: saturate(0);
      opacity: 0.5;
      transition: all 0.25s ease-in-out;
    }
  }
`

const Constrain = styled('div')`
  max-width: 1440px;
  margin: auto;
`

const Padding = styled('div')`
  padding: 5rem 2.5rem 2.5rem;

  @media (max-width: 425px) {
    padding-bottom: 3.5rem;
  }
`

class IndexPage extends Component {

  constructor() {
    super()
    this.state = {
      contact: null,
      socialProof: null,
      course: true
    }
  }

  render() {
    return (
      <Layout>
        <StaticQuery query={graphql`
          query homeQuery{
            file(relativePath: { eq: "home.md"}) {
              childMarkdownRemark {
                frontmatter {
                  title
                  image
                  brands {
                    name
                    logo
                    url
                  }
                  showCourse
                  courseTitle
                  courseDescription
                  courseDate
                  courseCost
                  seoTitle
                  seoImage
                  seoDescription
                }
                html
              }
            }
          }
        `}
        render={data => (
          <div
            className={css`max-width: 1680px; margin: auto;`}
          >
            <Header
              socialProof={this.state.socialProof}
              getStarted={this.state.contact}
            />
            <Hero
              heading={data.file.childMarkdownRemark.frontmatter.title}
              image={data.file.childMarkdownRemark.frontmatter.image}
              content={data.file.childMarkdownRemark.html}
              scroll={this.state.contact}
            />

            {/* Social Proof */}
            <SocialProof
              ref={(section) => !this.state.socialProof && this.setState({ socialProof: section })}
            >
              <Triangle color={"#fff"} />
              <Constrain>
                <Padding>
                  <div className={css`
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-gap: 60px;
                align-items: center;

                @media (max-width: 768px) {
                  grid-template-columns: 1fr;
                  text-align: center
                }
              `}>
                <h2>Who I've Helped</h2>
                  <div className="logos">
                    {data.file.childMarkdownRemark.frontmatter.brands.map((brand, i) => (
                      <a
                        key={i}
                        href={brand.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={brand.logo} alt={brand.name}/>
                      </a>
                    ))}
                  </div>
                </div>
              </Padding>
            </Constrain>
          </SocialProof>

          {/* Instagram 101 */}
            {data.file.childMarkdownRemark.frontmatter.showCourse &&
              <section style={{ backgroundColor: '#70CBD3', color: '#FCFCFC' }}>
                <Constrain>
                  <Padding>
                    <div className={css`
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    grid-gap: 60px;
                    align-items: center;

                    @media (max-width: 768px) {
                      grid-template-columns: 1fr;
                      text-align: center
                    }
                  `}>
                      <div>
                        <h4 style={{ marginBottom: 10 }}>${data.file.childMarkdownRemark.frontmatter.courseCost}</h4>
                        <h2 style={{ color: '#FCFCFC' }}>{data.file.childMarkdownRemark.frontmatter.courseTitle}</h2>
                      </div>
                      <div>
                        <ReactMarkdown source={data.file.childMarkdownRemark.frontmatter.courseDescription}/>
                        <p><strong>Course Info:</strong></p>
                        <ul>
                          <li>Cost: ${data.file.childMarkdownRemark.frontmatter.courseCost}</li>
                          <li>Date: {moment(data.file.childMarkdownRemark.frontmatter.courseDate, 'YYYY-MM-DD' +
                            ' kk:mm:ss +0000 ').format('MMMM Do, YYYY')}</li>
                        </ul>
                        <Checkout
                          title={data.file.childMarkdownRemark.frontmatter.courseTitle}
                          date={data.file.childMarkdownRemark.frontmatter.courseDate}
                          amount={data.file.childMarkdownRemark.frontmatter.courseCost}
                        />
                      </div>
                    </div>
                  </Padding>
                </Constrain>
              </section>
            }

          {/* Contact Form */}
          <Contact ref={(section) => !this.state.contact && this.setState({ contact: section })} />

        </div>
        )}
      />

      </Layout>
    )
  }
}

export default IndexPage
